import React, { lazy, Suspense } from 'react';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { authProvider } from 'shared/services/authProvider';
import {
  getFromLocalStorage,
  hasTimeExpired
} from '../LogoutWarningPopup/LogoutWarningPopup.helpers';
import { SESSION_EXPIRES_AT } from '../../shared/constants/autologout.const';
import { getAppTheme } from '../../theme';
import { getWindow, signOut } from './AuthUtils';
import { Loader } from '../../components/Loader/Loader';

const AppContainerWrapper = () => {
  const host = getWindow().location.hostname;
  const isLocalDevelopment =
    host === 'localhost' ||
    host === '127.0.0.1' ||
    process.env.REACT_APP_SBP_ADFS ||
    process.env.REACT_APP_LOCAL_BUILD;
  const storageLogoutTimeout = getFromLocalStorage(SESSION_EXPIRES_AT);
  const appTheme = getAppTheme('default');

  if (
    storageLogoutTimeout &&
    hasTimeExpired(storageLogoutTimeout) &&
    !isLocalDevelopment
  ) {
    signOut();
  }

  return (
    <Suspense fallback={<Loader absoluteCentered={true} />}>
      <AzureAD provider={authProvider} forceLogin={false}>
        {({ authenticationState, error }: any) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated: {
              const AppContainer = lazy(() => import('./AppContainer'));
              return <AppContainer />;
            }
            case AuthenticationState.Unauthenticated: {
              if (error) {
                const UnauthorizedRoutes = lazy(
                  () => import('../Routes/UnauthorizedRoutes')
                );
                return <UnauthorizedRoutes />;
              }
              if (isLocalDevelopment) {
                const AppContainer = lazy(() => import('./AppContainer'));
                return <AppContainer />;
              }

              const UnauthorizedRoutes = lazy(
                () => import('../Routes/UnauthorizedRoutes')
              );
              return (
                <ThemeProvider theme={appTheme}>
                  <StyledThemeProvider theme={appTheme}>
                    <UnauthorizedRoutes />
                  </StyledThemeProvider>
                </ThemeProvider>
              );
            }
            default:
              return null;
          }
        }}
      </AzureAD>
    </Suspense>
  );
};

export default AppContainerWrapper;
