export const LOCAL_STORAGE_TOKEN_KEY = 'oidc.token';
export const MSAL_COOKIE_KEY = `msal.${window.APP_CONFIG.AAD_CLIENT_ID}.idtoken`;

export const SPACE = ' ';

export enum ANSWER {
  YES = 'reportsSteps.button.yes',
  NO = 'reportsSteps.button.no'
}

export const NA = 'overview.NA';

export const DATE_FORMAT = 'd MMM yyyy';
export const loginCompletedStorageKey = 'loginCompleted';
export const DEFAULT_USER_LANG = 'en-US';
export const DEFAULT_USER_LANGUAGE = 'English (United States)';
export enum ALERT_TYPES {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export enum APP_CONTEXT_FIELDS {
  IS_TUTORIAL_CLICKED = 'isTutorialClicked'
}

export enum PLAN_CODE_STRINGS {
  MONTE_CARLO = 'ap-calc-sbp-mc', // rtsr, atsr, sar
  BLACK_SCHOLES = 'ap-calc-sbp-bs',
  BINOMINAL = 'ap-calc-sbp-bin',
  MONITORING = 'ap-calc-sbp-mon'
}

export enum STATUS_CODES {
  APPROVED = 'approved'
}

export enum USER_ROLES {
  USER = 'user',
  INTERNAL_ADMIN = 'internalAdmin'
}

export enum COUNTRY_CODES {
  USA = 'USA',
  AUS = 'AUS',
  CAN = 'CAN',
  GBR = 'GBR',
  CHN = 'CHN'
}

export enum COUNTRY_NAMES {
  AUS = 'Australia',
  MYS = 'Malaysia',
  SGP = 'Singapore',
  US = 'United States',
  UK = 'United Kingdom'
}

export enum UserSettingsNumberFormat {
  '1,000,000.00' = ',.',
  '1.000.000,00' = '.,',
  '1 000 000,00' = ' ,'
}

export const POP_UP = 'POP_UP';

export const dateFormatList = [
  {
    label: 'MM/DD/YYYY',
    format: 'MM/dd/yyyy',
    id: 'df1',
    checked: true
  },
  {
    label: 'YYYY/MM/DD',
    format: 'yyyy/MM/dd',
    id: 'df2',
    checked: false
  },
  {
    label: 'DD/MM/YYYY',
    format: 'dd/MM/yyyy',
    id: 'df3',
    checked: false
  },
  {
    label: 'DD Month YYYY',
    format: 'dd MMMM yyyy',
    id: 'df4',
    checked: false
  }
];

export const numberFormatList = [
  {
    label: '1,000,000.00',
    format: '#,###,###.##',
    id: 'nf1',
    checked: true
  },
  {
    label: '1.000,000,00',
    format: '#.###.###,##',
    id: 'nf2',
    checked: false
  },
  {
    label: '1 000 000,00',
    format: '# ### ###,##',
    id: 'nf3',
    checked: false
  }
];

export const paginationPageSize = 10;

export const industriesList = [
  { label: 'Investment Management', value: '1' },
  { label: 'Automotive', value: '2' },
  { label: 'Banks', value: '3' },
  { label: 'Brokers', value: '4' },
  { label: 'Building', value: '5' },
  { label: 'Consumption', value: '6' },
  { label: 'Education', value: '7' },
  { label: 'Energy and Water', value: '8' },
  { label: 'Government', value: '9' },
  { label: 'Laboratories', value: '10' },
  { label: 'Manufacture', value: '11' },
  { label: 'Media and Entertainment', value: '12' },
  { label: 'Mining', value: '13' },
  { label: 'Real Estate Business', value: '14' },
  { label: 'Oil and Gas', value: '15' },
  { label: 'Health', value: '16' },
  { label: 'Insurance', value: '17' },
  { label: 'Services', value: '18' },
  { label: 'Non profit', value: '19' },
  { label: 'Technology', value: '20' },
  { label: 'Telecommunications', value: '21' },
  { label: 'Transportation', value: '22' },
  { label: 'Tourism', value: '23' },
  { label: 'Others', value: '24' }
];
