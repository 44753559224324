import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const { APP_CONFIG } = window;

// Msal Configurations
const config = {
  auth: {
    authority: `${APP_CONFIG.AUTHORITY_URL}/${APP_CONFIG.AAD_TENANT_ID}`,
    clientId: APP_CONFIG.AAD_CLIENT_ID,
    redirectUri: APP_CONFIG.APP_URL,
    postLogoutRedirectUri: APP_CONFIG.APP_URL
  },
  cache: {
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ['user.read']
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: APP_CONFIG.APP_URL
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
